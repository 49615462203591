<header>
  <nav>
    <ul>
      <li><strong>DOA</strong></li>
    </ul>
    <ul>
      <li><a routerLink="/dashboard">Dashboard</a></li>
      <li><a href="#">Docs</a></li>
    </ul>
    <ul>
      <li><button class="secondary" (click)="getFacebookLoginService()">Log In</button></li>
      <li><button class="secondary">Sign Up</button></li>
    </ul>
  </nav>
</header>