<div class="dashboard">
  <h2>Dashboard</h2>
  <p>Visualize your Facebook Ads data!</p>

  <button (click)="getFacebookData()">Show data</button>
  <span id="data">{{data | json }}</span>

  <div>
    {{response}}
  </div>

  <div id="campaign-selector">
    <div class="grid">
      <div>
        <label for="adaccounts">Adaccount</label>

        <select id="adaccounts" name="adaccounts" (change)="getSelectedElement(adAccountSelector, 'adAccount')" #adAccountSelector>
          @for (account of adaccounts; track account.name){
          <option [value]=account.name>{{account.name}}</option>
          } @empty {
          <option value="">No Options</option>
          }
        </select>
      </div>

      <div>
        <label for="Campaigns">Campaign</label>

        <select id="Campaigns" name="Campaigns" (change)="getSelectedElement(campaignSelector, 'campaign')" #campaignSelector>
          @for (campaign of campaigns; track campaign.name){
          <option [value]=campaign.name>{{campaign.name}}</option>
          } @empty {
          <option value="">No Options</option>
          }
        </select>
      </div>

      <div>
        <label for="AdSet">Ad Set</label>

        <select id="AdSet" name="AdSet" (change)="getSelectedElement(adSetSelector, 'adset')" #adSetSelector>
          @for (adSet of adSets; track adSet.name){
          <option [value]=adSet.name>{{adSet.name}}</option>
          } @empty {
          <option value="">No Options</option>
          }
        </select>
      </div>

      <div>
        <label for="Ad">Ad</label>

        <select id="Ad" name="Ad" (change)="getSelectedElement(adSelector, 'ad')" #adSelector>
          @for (ad of ads; track ad.name){
          <option [value]=ad.name>{{ad.name}}</option>
          } @empty {
          <option value="">No Options</option>
          }
        </select>
      </div>
    </div>
  </div>

  <!-- <div style="border: 5px red;height: 100px; background-color: grey;">
    <label for="adaccounts">Choose your adaccount from the list:</label>
    <input list="adaccounts" name="adaccount" id="adaccount">
    
    <datalist id="adaccounts">
      @for (account of adaccounts; track account.name){
        <option [value]=account.name>{{account.name}}</option>
      } @empty {
        <option value="">No Options</option>
      }
    </datalist>
  </div> -->

  <!-- <div id="campaign-selector">
    <div class="grid">
      <label for="disabled">
        Campaign
        <input type="text" id="disabled" name="disabled" placeholder="Disabled" disabled="">
      </label>
      <label for="disabled">
        Ad Set
        <input type="text" id="disabled" name="disabled" placeholder="Disabled" disabled="">
      </label>
      <label for="disabled">
        Ad
        <input type="text" id="disabled" name="disabled" placeholder="Disabled" disabled="">
      </label>
    </div>
  </div> -->



  <div class="chart-wrapper">
    <div class="chart-title-wrapper">
      <h2>Line Chart</h2>
      <div #firstChart class="chart-container"></div>
    </div>
  </div>

  <button (click)="generateChart()">Load Chart</button>

  <button (click)="generateDropdowns()">ViewJson</button>

</div>